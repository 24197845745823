.App {
  text-align: center;
  font-family: 'Fira Code', monospace;
}

.App-header {
  align-items: center;
  justify-content: center;
}

.savedFrameDef-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 250px;
}

.savedFrameDef-card:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.3s ease;
}

.savedFrameDef-card:hover .delete-button {
  visibility: visible;
}

.delete-button {
  visibility: hidden;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: red;
  font-size: 24px; 
}